import { CmsGuideLayout } from "../../../components/CmsGuideLayout"
import { lorem } from "../../../lib/lorem"
import { PageDataBodyCallToAction } from "../../../slices/PageDataBodyCallToAction"

const Component = () => {
	return (
		<CmsGuideLayout>
			<PageDataBodyCallToAction
				heading={lorem.sentences(1)}
				linkText="Learn More"
				link="#"
				subheading={lorem.sentences(1)}
			/>
		</CmsGuideLayout>
	)
}

export default Component
